/* iOS Cordova Tweak */
.device-cordova.device-ios {
  height: 100vh;
}

/* Your app custom styles here */

html,
body {
  background: #F9F9F9;
}

body {
  font-family: 'Nunito', sans-serif !important;

  --f7-tabbar-labels-height: 62px;
  --f7-navbar-height: 62px;
  --f7-toolbar-height: 62px;
}

h1 {
  font-weight: bold;
  font-size: 24px;
  line-height: 110%;
  margin: 16px 0px;
}

h2 {
  font-weight: bold;
  font-size: 20px;
  line-height: 125%;
}

h3 {
  font-weight: bold;
  font-size: 18px;
  line-height: 1.1;
}

.mb-1 {
  margin-bottom: 4px !important;
}

.mb-2 {
  margin-bottom: 8px !important;
}

.mb-3 {
  margin-bottom: 16px !important;
}

.mb-4 {
  margin-bottom: 24px !important;
}

.mb-5 {
  margin-bottom: 32px !important;
}

.mt-1 {
  margin-top: 4px !important;
}

.mt-2 {
  margin-top: 8px !important;
}

.mt-3 {
  margin-top: 16px !important;
}

.mt-4 {
  margin-top: 24px !important;
}

.mt-5 {
  margin-top: 32px !important;
}

.block {
  margin: 24px 0px;
}

::-webkit-input-placeholder {
  /* Chrome */
  color: #284029;
  opacity: .5;
}

:-ms-input-placeholder {
  /* IE 10+ */
  color: #284029;
  opacity: .5;
}

::-moz-placeholder {
  /* Firefox 19+ */
  color: #284029;
  opacity: .5;
}

:-moz-placeholder {
  /* Firefox 4 - 18 */
  color: #284029;
  opacity: .5;
}

.page {
  background: #F9F9F9;
  --f7-checkbox-active-color: #25A82B;
  --f7-theme-color: #25A82B;
}

.page .color-green {
  --f7-theme-color: #25A82B;
  /* --f7-theme-color-rgb: 76, 217, 100; */
  --f7-theme-color-shade: #25A82B;
  --f7-theme-color-tint: #25A82B;
}

.page .color-green {
  --swiper-theme-color: #25A82B;
}

.popup .btn,
.sheet-modal .btn,
.page .btn {
  background-color: #25A82B;
  box-shadow: 0px 9px 14px rgba(37, 168, 43, 0.3);
  font-size: 16px;
  color: #FFFFFF;
  font-weight: bold;
  line-height: 1;
  padding: 22px 20px;
  border-radius: 24px;
  width: 100%;
  display: inline-block;
  box-sizing: border-box;
  text-align: center;
  border: none;

  font-family: 'Nunito', sans-serif !important;
  outline: none !important;
}

.sheet-modal .btn-disabled,
.sheet-modal .btn:disabled,
.page .btn-disabled,
.page .btn:disabled {
  color: #808080;
  background: #F2F2F2;
  box-shadow: none;
}


.input-wrap .smart-select .item-inner,
.input-wrap input[type="text"],
.input-wrap input[type="email"],
.input-wrap input[type="tel"],
.page input[type="text"],
.page input[type="email"],
.page input[type="tel"] {
  background: #FFFFFF;
  border: 1px solid #D4D9D4;
  box-sizing: border-box;
  border-radius: 24px;
  font-size: 14px;
  padding: 20px;
  width: 100%;
}

.input-wrap {
  position: relative;
}

.input-wrap .smart-select .item-inner .icn-next {
  transform: rotate(90deg);
}

.input-wrap .smart-select .item-inner .item-title,
.input-wrap label {
  font-size: 12px;
  line-height: 1;
  color: #284029;
  opacity: 0.5;
  position: absolute;
  left: 20px;
  top: 10px;
}

.input-wrap .smart-select .item-inner,
.input-wrap input[type="text"],
.input-wrap input[type="email"],
.input-wrap input[type="tel"] {
  padding: 28px 20px 11px;
}

.input-wrap-error input[type="text"],
.input-wrap-error input[type="email"],
.input-wrap-error input[type="tel"] {
  border-color: rgba(234, 70, 70, 0.5);
  background: rgba(234, 70, 70, 0.05);
}

.input-wrap .icn {
  position: absolute;
  top: 50%;
  margin-top: -12px;
  right: 20px;
}

.input-wrap .smart-select .item-inner .item-after {
  line-height: 1.1;
}

.price {
  font-weight: 800;
  font-size: 24px;
}

.price-new {
  color: #25A82B;
  font-weight: 800;
  font-size: 24px;
}

.price sup,
.price-new sup {
  font-size: 9px;
  font-weight: bold;
  color: #FFFFFF;
  background: #25A82B;
  border-radius: 26px;
  padding: 3px 4px;
}

.price-old {
  color: #262626;
  text-decoration: line-through;
  opacity: .5;
  font-weight: bold;
}


.post-card,
.category-card,
.index-card {
  display: block;
  background: #FFFFFF;
  border-radius: 24px;
  padding: 24px 112px 24px 24px;
  margin-bottom: 24px;
  box-shadow: 0px 10px 14px rgba(0, 0, 0, 0.03);
  position: relative;
  overflow: hidden;
}

.post-card-title,
.category-card-title,
.index-card-title {
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 4px;
  color: #000000;
}

.post-card-text,
.category-card-text,
.index-card-text {
  font-weight: 600;
  font-size: 11px;
  line-height: 1.25;
  color: #000000;
  opacity: 0.5;

  position: relative;
  z-index: 3;
}

.index-card-image {
  position: absolute;
  right: -15px;
  top: 0;
  height: 100%;
  width: auto;
}

.post-card,
.category-card {
  padding-right: 72px;
}

.post-card .icn-next,
.category-card .icn-next {
  position: absolute;
  right: 24px;
  top: 50%;
  margin-top: -12px;
}

/* .category-card-title {
  font-size: 20px;
  margin-bottom: 6px;
}

.category-card-text {
  font-size: 14px;
} */

.post-card-title {
  margin: 0px;
  font-weight: normal;
  font-size: 16px;
}

.navbar-bg {
  background: #F9F9F9;
}

.toolbar-bottom:after,
.ios .toolbar-bottom-ios:after,
.md .toolbar-bottom-md:after,
.aurora .toolbar-bottom-aurora:after,
.tab-link-highlight,
.navbar-bg:before,
.navbar-bg:after {
  display: none !important;
}

.navbar .title {
  font-weight: bold;
  font-size: 18px;
  line-height: 1;
}

.navbar .title small {
  font-size: 12px;
}

.navbar .title-image {
  line-height: 0;
}

.navbar a.link {
  color: #25A82B;
  font-weight: bold;
  font-size: 12px;
}

.navbar a.link .icn-back+span {
  margin-left: 0px;
  display: none !important;
}


.sheet-modal-bottom,
.sheet-modal:not(.sheet-modal-top) {
  border-radius: 16px 16px 0px 0px;
}

.sheet-modal-bottom:before,
.sheet-modal:not(.sheet-modal-top):before {
  display: none;
}

.sheet-modal-bottom::after,
.sheet-modal:not(.sheet-modal-top)::after {
  content: "";
  width: 36px;
  height: 4px;
  background: #8A8A8A;
  border-radius: 8px;
  position: absolute;
  left: 50%;
  margin-left: -18px;
  top: 12px;
}


.toolbar-inner {
  justify-content: space-around;
}

.toolbar-inner a {
  padding: 0px 20px;
}

.tabbar-labels .tab-link,
.tabbar-labels .link {
  justify-content: center;
  align-items: center;
}

.views>.toolbar,
.view>.toolbar,
.page>.toolbar {
  background: #FFFFFF;
  box-shadow: 0px -10px 14px rgba(0, 0, 0, 0.03);
}

.views>.toolbar::before,
.view>.toolbar::before,
.page>.toolbar::before {
  display: none;
}


.category-view {
  font-size: 16px;
  line-height: 1.5;
}

.category-view p {
  font-size: 16px;
  line-height: 1.5;
}

.category-view small {
  font-size: 14px;
  display: inline-block;
}

.category-view-image {
  /* height: 215px;
  position: absolute;
  bottom: 30px;
  right: -30px; */
  width: 100%;
  height: auto;
}

.service-view {
  max-height: 90vh;
}

.service-view-title {
  font-weight: bold;
  font-size: 24px;
  line-height: 1;
  margin: 24px;
  text-align: center;
}

.post-view {
  max-height: 90vh;
}

.post-view-title {
  font-weight: bold;
  font-size: 20px;
  line-height: 1.3;
  color: #262626;
  white-space: normal;

  margin-bottom: 24px;
  margin-top: 48px;
}

.post-view-content,
.post-view-content p {
  font-size: 14px;
  line-height: 1.5;
}

.post-view-content h2 {
  background: #25A82B;
  border-radius: 24px;
  margin: 0px;
  margin-bottom: 16px;
  color: #fff;
  padding: 8px 12px;
  display: inline-block;
  font-weight: bold;
  font-size: 12px;
  line-height: 1;
}


.callback-view {
  text-align: center;
}

.callback-view-title {
  font-weight: bold;
  font-size: 24px;
  line-height: 1.3;
  color: #262626;
  white-space: normal;

  margin-bottom: 24px;
  margin-top: 48px;
}

.callback-view-content,
.callback-view-content p {
  font-size: 18px;
  line-height: 1.1;
}

.callback-view-content p {
  margin: 0px;
  margin-bottom: 24px;
}

.callback-view-content a[href*="tel"] {
  font-weight: bold;
  font-size: 32px;
  color: #25A82B;
  display: flex;
  align-items: center;
  justify-content: center;
}

.callback-view-content a[href*="tel"] .icn {
  margin-right: 18px;
}

.callback-view-content small {
  font-size: 16px;
  color: #808080;
  line-height: 1.25;
  display: block;
}


.quiz-item {
  background: #FFFFFF;
  box-shadow: 0px 10px 14px rgba(0, 0, 0, 0.03);
  border-radius: 24px;
  padding: 16px 32px 20px 24px;
  display: block;
  margin-bottom: 24px;
  position: relative;
}

.quiz-item-title {
  font-weight: bold;
  font-size: 12px;
  line-height: 1;
  background: #F2F2F2;
  border-radius: 24px;
  display: inline-block;
  padding: 8px 12px;
}

.quiz-item-title.active {
  background-color: #25A82B;
  color: #FFFFFF;
}

.quiz-checkbox {
  margin-top: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row-reverse;
}

.quiz-checkbox input {
  display: none;
}

.quiz-checkbox .icn {
  border: 1px solid #25A82B;
  border-radius: 50px;
}

.quiz-checkbox input:checked+.icn {
  background-color: #25A82B;
}

.quiz-checkbox-label {
  font-weight: 600;
  font-size: 14px;
  max-width: calc(100% - 50px);
}

.block-information {
  font-size: 12px;
  line-height: 1.4;
  color: #284029;
  opacity: 0.7;

  position: relative;
  padding-left: 30px;
  margin-top: 16px;
}

.block-information .icn-info {
  position: absolute;
  left: 0px;
  top: 0px;
}

.form-row {
  margin-bottom: 20px;
}


.service-card {
  margin-bottom: 24px;
  display: block;
}

label.service-card {
  border: 1px solid rgba(0, 0, 0, 0.15);
  box-sizing: border-box;
  box-shadow: 0px 10px 14px rgba(0, 0, 0, 0.03);
  border-radius: 24px;
  padding: 24px;
  background: #FFFFFF;
}

.service-card input {
  display: none;
}

.service-card .icn {
  border: 1px solid #25A82B;
  border-radius: 50px;
}

.service-card input:checked+.icn {
  background-color: #25A82B;
}

.service-card h3,
.service-card h2 {
  margin: 0px;
}

.service-card-header {
  display: grid;
  margin-bottom: 16px;
  grid-template-columns: 1fr auto;
  grid-column-gap: 16px;
}

.service-card-body {
  font-size: 14px;
  line-height: 150%;
  color: #000000;
  opacity: 0.7;
}

.service-card-footer {
  margin-top: 24px;
  color: #284029;
}

.service-card-footer .price-old {
  margin-right: 12px;
}

.service-card-footer .price-new sup,
.service-card-footer .price sup {
  margin-left: 5px;
}

.swiper-buttons {
  position: fixed;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background-color: #FFFFFF;
  z-index: 9;

  padding-bottom: 16px;
  padding-top: 16px;
  padding-left: calc(var(--f7-block-padding-horizontal) + var(--f7-safe-area-left));
  padding-right: calc(var(--f7-block-padding-horizontal) + var(--f7-safe-area-right));
  box-shadow: 0px -10px 14px rgba(0, 0, 0, 0.03);
}

.swiper-buttons-height {
  height: 64px;
  width: 100%;
}

.swiper-hello {
  padding-top: 36px;
  position: relative;
}

.swiper-hello,
.swiper-hello p,
.swiper-hello ul {
  font-size: 14px;
  line-height: 1.5;
}

.swiper-hello h2 {
  font-weight: bold;
  font-size: 24px;
  line-height: 1.1;
  margin: 0px 0px 24px 0px;
}

.swiper-hello .swiper-pagination {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
}

.swiper-hello .swiper-pagination-bullet {
  background: #D4D4D4;
  border-radius: 8px;
  width: 28%;
  height: 4px;
}

.swiper-hello .swiper-pagination-bullet-active {
  background: #25A82B;
}

.swiper-hello-footer {
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 100%;
}

.swiper-hello-img1 {
  height: 240px;
  width: 100%;
  margin-bottom: 32px;
  background: no-repeat center;
  background-size: auto 100%;
}

.swiper-hello-img2 {
  height: 300px;
  width: 100%;
  margin-bottom: 32px;
  background: no-repeat center;
  background-size: auto 100%;
}


@media (min-width: 992px) {
  .page-content {
    padding-bottom: 110px;
  }
  /* .navbar-bg {
    background: #ffffff;
    border-bottom: 1px solid #eee;
  } */
  .block-grid {
    display: grid;
    grid-template-columns: 1fr 1fr ;
    grid-column-gap: 20px;
  }
  .tabs .tab {
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
  }
  .sheet-modal .block {
    display: block;
  }
  .views>.toolbar-bottom {
    max-width: 300px;
    border-radius: 300px;
    left: 50%;
    margin-left: -150px;
    bottom: 30px;
    border: 1px solid #eee;
  }
  .category-view-image {
    display: none;
  }
  
  .sheet-modal-bottom, 
  .sheet-modal:not(.sheet-modal-top) {
    height: auto;
    display: block;
    width: 600px;
    left: 50%;
    margin-left: -300px;
    border-radius: 16px;
    overflow: hidden;
    bottom: auto;
    top: 40px;
  }
  .post-card, 
  .category-card, 
  .index-card {
    padding: 32px 112px 32px 32px;
  }
  .post-card-text, 
  .category-card-text, 
  .index-card-text {
    font-size: 14px;
  }
  .post-card-title, 
  .category-card-title, 
  .index-card-title {
    font-size: 18px;
  }
  .post-view-content, 
  .post-view-content p {
    font-size: 16px;
  }
}